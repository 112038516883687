:root {
  --foreground: #222;
  --background: white;

  --body-max-width: 1200px;
  --column-width: 34rem;
  --hor-padding: 3rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  color: var(--foreground);
  background: var(--background);

  max-width: var(--body-max-width);
  margin: 0 auto;
  display: flex;
}

a {
  color: inherit;
}

ul {
  list-style-type: none;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground: #eee;
    --background: #111;
  }

  html {
    color-scheme: dark;
  }
}
